<template>
  <div class="consensu-button animated bounce"><div class="consensu-button-check"></div></div>
</template>

<script>
export default {
  name:"consensu-button"
}
</script>

<style>

.consensu-button {
  background: #000;
  height: 50px;
  width: 50px;
  margin: 10px;
  border-radius: 50%;
  border: 1px solid #000;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  z-index: 1000;
}

.consensu-animation-bounce {
  animation: bounce 1s infinite alternate;
  -webkit-animation: bounce 1s infinite alternate;
}

.consensu-button:hover {
  animation: none;
}

.consensu-button-check {
  position: relative;
  top: 30px;
  left: 25px;
  width: 40px;
  height: 10px;
  background: #fff;
  transform-origin: 0 100%;
  transform: rotate(-45deg);
  box-shadow: 1px 1px 1px 1px #c3c3c3;
}

.consensu-button-check:before {
  content: "";
  position: absolute;
  left: -2px;
  top: -12px;
  bottom: 100%;
  width: 10px;
  height: 22px;
  background: #fff;
  box-shadow: inset -0.2rem -2rem 2rem #fff;
}

@keyframes bounce {
  from {
    transform: translateY(0px);
  }

  to {
    transform: translateY(-15px);
  }
}

@-webkit-keyframes bounce {
  from {
    transform: translateY(0px);
  }

  to {
    transform: translateY(-15px);
  }
}
</style>